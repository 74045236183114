import React, {useEffect, useRef} from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
} from "@mui/material";
import Person2TwoToneIcon from "@mui/icons-material/Person2TwoTone";
import { useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DSC00123 from "../assets/DSC00123.jpeg";
import comp1 from "../assets/comp1.jpeg";
import comp3 from "../assets/comp3.jpeg";
import hAndAL from "../assets/hearts-and-arrows-large.jpeg";

import Header from "../components/Header";
import Footer from "../components/Footer";

const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const Company = () => {
  const key = useRef(null)
  const location = useLocation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const executeScrollKey = () => key.current.scrollIntoView() 

  useEffect(() => {
    if (location.hash === '#key-people') {
      executeScrollKey()
    }
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <Box >
        <Container maxWidth="xl">
            <Header />
            <Stack
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={2}
              alignItems= 'center'
            >
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={3} alignItems="flex-start" >
                <Grid item xs={8}>
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Our History
                    </Typography>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      {isMobile ? <Box/> : 
                      <Stack direction="row" spacing={5}>
                      <Box
                        sx={{ borderRadius: "16px" }}
                        component="img"
                        alt="Your logo."
                        src={comp1}
                      ></Box>
                      <Box
                        sx={{ borderRadius: "16px" }}
                        component="img"
                        alt="Your logo."
                        src={comp3}
                      ></Box>
                    </Stack>}
                      
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Luc Vets is a second generation diamantaire who founded
                        his company in the early seventies.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Back then the production was situated in the Kempen
                        area, a rural area just outside Antwerp. Labour was
                        cheaper than in Antwerp and the production grew quickly
                        with quick turnaround times of the stock. The majority
                        of the production consisted of -11 sizes and 10/ct.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        In the mid eighties an opportunity arose to shift production overseas. This soon
                        took over the smallest and cheapest product categories
                        from the Belgian production thus freeing up workers to
                        expand into bigger sizes. The following years were
                        marked by a gradual expansion of the production until
                        the late nineties where we realised that specific
                        factories for specific productlines would lead to a
                        general improvement of quality and production
                        efficiency.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        This lead to the startup of our far east production
                        where we are – up until today – polishing our stars and
                        single cuts.
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4} >
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Our Mission
                    </Typography>
                    <Stack
                      spacing={2}
                      
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Typography></Typography>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        Our mission is to become an integral part of your
                        business. We believe in “zero defect service”.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Our product is more than an ideally cut diamond, it is a
                        complete package that will give you the best possible
                        polished diamond for the best price, graded towards your
                        specific needs and delivered to you within 48hrs. And
                        all this without the need for you to spend time on QC to
                        make rejections.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        In short: our front office is your back office!
                      </Typography>
                      <Box
                        sx={{ width: 300, height: 250, borderRadius: "16px" }}
                        component="img"
                        alt="Your logo."
                        src={DSC00123}
                      ></Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
                <Grid item xs={8}>
                <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                    spacing={2}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Company Profile
                    </Typography>
                    <Stack spacing={2}
                      style={{ padding: "2px", margin: "5px" }}>
                    <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                      In the beginning the company produced only stars and
                      melees in better qualities. Today our production covers
                      all qualities and colors from .80mm up to 1ct in single
                      cut and round brilliants in top quality makes. We carry a
                      full range of Excellent, AGS ‘0’ as well as Hearts and
                      Arrows goods.
                    </Typography>
                    <Stack spacing={2} direction={isMobile ? 'column' : 'row'}>
                      <Box
                        sx={{ width: isMobile ? 300 : 400, height: 200, borderRadius: "16px" }}
                        component="img"
                        alt="Your logo."
                        alignItems='center'
                        src={hAndAL}
                      ></Box>
                      <Stack spacing={2}>
                        <Typography style={{ color: "#565656", fontSize: 15 }}>
                          The company’s origins lie in Belgium but production
                          and sales truly made our company global. 
                        </Typography>
                        <Typography style={{ color: "#565656", fontSize: 15 }}>
                          Because of our reputation as a manufacturer of
                          high-end goods we were able to establish commercial
                          relations with some of the best-known brand names
                          across the globe. Be it the USA, Switzerland or China
                          we supply your market with our ideal makes. The
                          perfection of our make could only be achieved by a
                          continuous investment in our staff and our production
                          facilities and processes.
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography style={{ color: "#565656", fontSize: 15 }}>
                      Our worldwide experience guarantees that you will get what
                      you need – we have a thorough understanding of your
                      market’s requirements and we will supply you accordingly.
                      Our own developed grading system allows for a high grade
                      of customization should you have specific requirements in
                      terms of make, color or quality. Our flexibility in
                      grading will turn us into your virtual back office
                      resulting in substantial savings for you in Quality
                      Control and shipping.
                    </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack divider={<Divider orientation="horizontal" flexItem />}
                    ref={key} spacing={2}>
                    <Typography
                      style={{ fontSize: 30, fontWeight: "bold" }}
                    >
                      Key People
                    </Typography>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={2}>
                        <Person2TwoToneIcon style={{ width: 40, height: 40 }} />
                        <Stack>
                          <Typography style={{ fontWeight: "bold" }}>
                            Luc Vets
                          </Typography>
                          <Typography
                            style={{ fontSize: 12, color: "#565656" }}
                          >
                            Founder and CEO
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Person2TwoToneIcon style={{ width: 40, height: 40 }} />
                        <Stack>
                          <Typography style={{ fontWeight: "bold" }}>
                            Wim Ruythooren
                          </Typography>
                          <Typography
                            style={{ fontSize: 12, color: "#565656" }}
                          >
                            Director
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Person2TwoToneIcon style={{ width: 40, height: 40 }} />
                        <Stack>
                          <Typography style={{ fontWeight: "bold" }}>
                            Dominique Vets
                          </Typography>
                          <Typography
                            style={{ fontSize: 12, color: "#565656" }}
                          >
                            Director
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Footer />
            </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Company;
