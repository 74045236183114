import React from 'react'
import { Box, Typography, Stack, Divider} from '@mui/material';
import {useNavigate, useLocation} from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo_LV from "../assets/logo_LV.gif"


const boxStyle = {
    backgroundColor: "#971A3B",
    padding: "2px", 
   
}

const HeaderInventory = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const click = (path) => {
        navigate(path)
    }

  return (
    <Box  >
      <Stack display={isMobile ? 'block' : 'flex'}>
            <Box  sx={boxStyle} >
                <Stack alignItems="center" direction={isMobile ? 'column' : 'row'} spacing={2} divider={<Divider orientation="vertical" flexItem />} >
                    <Box component="img" alt="Your logo."  src={logo_LV}/>
                    <Typography onClick = {() => click("/")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/' ? "bold" : 150}}>Home</Typography>
                    <Typography onClick = {() => click("/company")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/company' ? "bold" : 150}}>Company</Typography>
                    <Typography onClick = {() => click("/products")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/products' ? "bold" : 150}}>Products</Typography>
                    <Typography onClick = {() => click("/inventory")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/inventory' ? "bold" : 150}}>Inventory</Typography>
                    <Typography onClick = {() => click("/sustainability")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/sustainability' ? "bold" : 150}}>Sustainability</Typography>
                    <Typography onClick = {() => click("/contact")} style={{color: "white", fontSize: '2rem', fontWeight: location.pathname === '/contact' ? "bold" : 150}}>Contact</Typography>
                </Stack>
            </Box>
          </Stack>
    </Box>
  )
}

export default HeaderInventory