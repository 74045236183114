import { createContext, useState } from "react";
import axios from "axios";

const InventoryContext = createContext();

const clarityMap = {
  I2: 110,
  I1: 100,
  SI3: 90,
  SI2: 80,
  SI1: 70,
  VS2: 60,
  VS1: 50,
  VVS2: 40,
  VVS1: 30,
  IF: 20,
  FL: 10,
};

const colorMap = {
  M: 100,
  L: 90,
  K: 80,
  J: 70,
  I: 60,
  H: 50,
  G: 40,
  F: 30,
  E: 20,
  D: 10,
};

const cutMap = {
  Fair: 10,
  Good: 20,
  'Very Good': 30,
  Excellent: 40,
  Ideal: 50,
};

const polishMap = {
  Fair: 10,
  Good: 20,
  'Very Good': 30,
  Excellent: 40,
};

const symmetryMap = {
  Fair: 10,
  Good: 20,
  'Very Good': 30,
  Excellent: 40,
};

export const InventoryProvider = ({ children }) => {
  const [shape, setShape] = useState(["Round", "Pear", "Oval"]);
  const [size, setSize] = useState([5, 0]);
  const [price, setPrice] = useState([1000000, 0]);
  const [clarity, setClarity] = useState(["FL", "I2"]);
  const [color, setColor] = useState(["M", "D"]);
  const [cut, setCut] = useState(["Ideal", "Fair"]);
  const [polish, setPolish] = useState(["Excellent", "Fair"]);
  const [symmetry, setSymmetry] = useState(["Excellent", "Fair"]);
  const [lab, setLab] = useState(["GIA", "HRD"]);
  const [fluorescence, setFluorescence] = useState([
    "Very Strong",
    "Strong",
    "Medium",
    "Faint",
    "Very Slight",
    "None",
  ]);

  const [isLoading, setIsLoading] = useState(true);
  const [diamonds, setDiamonds] = useState(undefined);
  const [certificate, setCertificate] = useState(undefined)
  const [diamondsFilter, setDiamondsFilter] = useState(undefined);
  const [meta, setMeta] = useState(true);
  const [pageState, setPageState] = useState(0)

  const baseURL =  "http://127.0.0.1:5000"//"https://lvbackend.herokuapp.com" 


  const getDiamonds = async () => {
    try {
      setIsLoading(true);
      const result = await axios.post(baseURL + `/inventory`, {
        shape: shape,
        size: size,
        price: price,
        color: color,
        clarity: clarity,
        cut: cut,
        symmetry: symmetry,
        lab: lab,
        fluorescence: fluorescence,
        polish: polish,
      });
      setDiamonds(result.data.diamonds);
      setDiamondsFilter(result.data.diamonds)
      setMeta(result.data.search_results);
      setIsLoading(false);
    } catch (error) {
      setDiamonds(undefined);
      setDiamondsFilter(undefined)
      setIsLoading(false);
      console.log(error);
    }
  };

  const getLink = async (report_number) => {
    try {
      const result = await axios.post(baseURL + `/certificate`, {
        report_number: report_number,
      });
      setCertificate(result.data)
    } catch (error) {
      console.log(error);
    }
  };

  const setDefault = () => {
    setShape(["Round", "Pear", "Oval"]);
    setSize([5, 0]);
    setPrice([1000000, 0]);
    setClarity(["FL", "I2"]);
    setColor(["M", "D"]);
    setCut(["Ideal", "Fair"]);
    setPolish(["Excellent", "Fair"]);
    setSymmetry(["Excellent", "Fair"]);
    setLab(["GIA", "HRD"]);
    setFluorescence([
      "Very Strong",
      "Strong",
      "Medium",
      "Faint",
      "Very Slight",
      "None",
    ]);
  };

  const sortRap = (choice) => {
    const choiceMap = {
      10: "total_sales_price_in_currency",
      20: "size",
      30: "rap_discount"
    }

    if (choice == 10) {
      setDiamonds(diamonds.sort((a,b) => a[choiceMap[choice]]*a[size] > b[choiceMap[choice]]*b[size] ? 1 : -1))
    } else {
      setDiamonds(diamonds.sort((a,b) => a[choiceMap[choice]] > b[choiceMap[choice]] ? 1 : -1))
    }
    
    
  }

  const filterDiamonds = () => {
    setDiamondsFilter(
    diamonds
        .filter(diam => fluorescence.includes(diam.fluor_intensity))
        .filter(diam => shape.includes(diam.shape))
        .filter(diam => lab.includes(diam.lab))
        .filter(
          diam =>
            diam.total_sales_price_in_currency*diam.size <= price[0] &&
            diam.total_sales_price_in_currency*diam.size >= price[1],
        )
        .filter(diam => diam.size <= size[0] && diam.size >= size[1])
        .filter(
          diam =>
            clarityMap[diam.clarity] >= clarityMap[clarity[0]] &&
            clarityMap[diam.clarity] <= clarityMap[clarity[1]],
        )
        .filter(
          diam =>
            colorMap[diam.color] <= colorMap[color[0]] &&
            colorMap[diam.color] >= colorMap[color[1]],
        )
        .filter(
          diam =>
            (cutMap[diam.cut] <= cutMap[cut[0]] &&
              cutMap[diam.cut] >= cutMap[cut[1]]) ||
            diam.cut === '',
        )
        .filter(
          diam =>
            polishMap[diam.polish] <= polishMap[polish[0]] &&
            polishMap[diam.polish] >= polishMap[polish[1]],
        )
        .filter(
          diam =>
            polishMap[diam.symmetry] <= symmetryMap[symmetry[0]] &&
            polishMap[diam.symmetry] >= symmetryMap[symmetry[1]],
        )
        )
  }

  return (
    <InventoryContext.Provider
      value={{
        isLoading,
        getDiamonds,
        diamonds,
        meta,
        setShape,
        setSize,
        setPrice,
        setClarity,
        setCut,
        setPolish,
        setLab,
        setSymmetry,
        setFluorescence,
        setColor,
        setDefault,
        setDiamonds,
        sortRap, 
        filterDiamonds, 
        diamondsFilter,
        color, 
        pageState, 
        setPageState,
        getLink, 
        certificate,
        setCertificate
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryContext;
