import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";

import Footer from "../components/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import InventoryContext from "../contexts/InventoryContext";
import Oval from "../assets/oval2.jpeg";
import Round from "../assets/round2.jpeg";
import Pear from "../assets/pear2.jpeg";

const imageMap = { Round: Round, Pear: Pear, Oval: Oval };

function Diamond() {
  const navigate = useNavigate();
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const { diamonds, isLoading, getDiamonds, getLink, certificate, setCertificate } =
    useContext(InventoryContext);
  const diamond = diamonds?.filter(
    (diam) => diam.cert_num == location.pathname.split("/")[2]
  )[0];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getCertificate = (report_number) => {
    getLink(report_number);
    setClicked(true);
  };

  const goBack = () => {
    setCertificate(undefined)
    navigate("/Inventory")
  }

  const openLink = (link) => {
    window.open(link)
  }

  useEffect(() => {
    if (diamonds === undefined) {
      getDiamonds();
    }
  }, []);

  if (isLoading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }} margin={20}>
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
          <Footer />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box>
        <Stack>
          <Paper>
            <Button
              style={{ color: "#971A3B", margin: 5 }}
              onClick={() => goBack()}
            >
              Go Back
            </Button>
            <Box>
              <Stack>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  alignItems="center"
                  justifyContent="center"
                  padding={3}
                >
                  <Box
                    sx={{ width: 200, height: 150 }}
                    component="img"
                    alt="diam_shape"
                    src={imageMap[diamond.shape]}
                  />
                  <Stack style={{ marginLeft: "5%", marginTop: "3%" }}>
                    <Typography style={{ fontSize: 30 }}>
                      {diamond.shape}, {diamond.size} Ct, {diamond.color}{" "}
                    </Typography>
                    <Typography> {diamond.cut} Cut</Typography>
                    <Typography> Graded by {diamond.lab}</Typography>
                    <Typography style={{ color: "#971A3B" }}>
                      {" "}
                      ${" "}
                      {Math.round(
                        diamond.total_sales_price_in_currency / diamond.size
                      )}{" "}
                      per carat
                    </Typography>
                    <Typography>
                      Rap.Discount: {Math.round(diamond.rap_discount * -100)}%
                    </Typography>

                    {clicked ? (
                      certificate === undefined ? (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 3,
                          }}
                        >
                          <CircularProgress size={20} />
                        </Box>
                      ) : certificate.data.getReport === null ? (
                        <Typography sx={{ color: "#971A3B" }}>
                          Loading certificate failed, contact us for more information.
                        </Typography>
                      ) : (
                        <Typography sx={{ color: "#971A3B" }} onClick={() => openLink(certificate.data.getReport.links.pdf)}>Click to open certificate</Typography>
                      )
                    ) : diamond.lab === "GIA" ? (
                      <Button
                        style={{
                          backgroundColor: "#971A3B",
                          color: "white",
                          marginTop: 3,
                        }}
                        onClick={() => getCertificate(diamond.cert_num)}
                      >
                        Check Certificate
                      </Button>
                    ) : null}
                  </Stack>
                </Stack>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  padding={5}
                >
                  <Typography sx={{ fontSize: 25 }}>Details</Typography>
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    
                    alignItems="space-between"
                  >
                    <Stack direction="row">
                      <Stack>
                        <Typography>Grading Lab</Typography>
                        <Typography>Carat Weigth</Typography>
                        <Typography>Cut</Typography>
                        <Typography>Color</Typography>
                        <Typography>Clarity</Typography>
                        <Typography>Price Per Carat</Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.lab}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.size}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.cut}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.color}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.clarity}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          ${" "}
                          {Math.round(
                            diamond.total_sales_price_in_currency / diamond.size
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row">
                      <Stack>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Depth %
                        </Typography>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Table %
                        </Typography>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Polish
                        </Typography>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Symmetry
                        </Typography>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Fluorescence
                        </Typography>
                        <Typography style={{ marginLeft: isMobile ? "0%" : "5%" }}>
                          Measurements
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.depth_percent}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.table_percent}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.polish}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.symmetry}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.fluor_intensity}
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", marginLeft: 20 }}
                        >
                          {diamond.meas_length}x{diamond.meas_width}-
                          {diamond.meas_depth}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        <Footer />
      </Box>
    );
  }
}

export default Diamond;
