import Router from './routes';
import './index.css';
import { useLoadScript } from '@react-google-maps/api';
import { InventoryProvider } from './contexts/InventoryContext';

const libraries = ['places'];

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCRj7AjW6JiGpHwdpgUVJ6dbuxIcJ_atbA",
    libraries: libraries,
  });

  if (!isLoaded) return null;

  return (
    <>
      <InventoryProvider>
        <Router/>
      </InventoryProvider>
    </>
  );
}

export default App;
