import React from 'react';
import { GoogleMap, Marker, MarkerF } from '@react-google-maps/api';

import Box from '@mui/material/Box';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '5px',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  fullscreenControl: true,
  // mapId: '794f0cbdfb7a403',
};

export default function Map() {

    const center = {'lat': 51.215050, 'lng': 4.419590}

  return (
    <div style={{ height: '100%', width: '100%' }} >
      
        <GoogleMap zoom={16} center={center} mapContainerStyle={containerStyle} options={options}>
          
            <MarkerF
              position={center}
            />
          
        </GoogleMap>
    </div>
  );
}
