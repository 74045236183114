import React, {useState, useContext} from "react";
import { Stack, Typography, Input } from "@mui/material";
import InventoryContext from "../contexts/InventoryContext";

const minValues = {Price: 0, Size: 0.01}
const maxValues = {Price: 1000000, Size: 5}

function InventoryInput({ type }) {
    const {
        setPrice,
        setSize,
      } = useContext(InventoryContext);
    const [min, setMin] = useState(minValues[type]);
    const [max, setMax] = useState(maxValues[type]);

    const setMap = {
        Size: setSize,
        Price: setPrice
    }

    const onMax = (e) => {
        setMax(e.target.value);
        setMap[type]((state) => ([e.target.value, state[1]]))
      };
    
      const onMin = (e) => {
        setMin(e.target.value);
        setMap[type]((state) => ([state[0], e.target.value]))
      };
  return (
    <Stack>
      <Typography sx={{color: 'gray'}}>{type}</Typography>
      <Stack direction="row" sx={{gap: 5}}>
        <Stack>
          <Typography>From:</Typography>
          <Input value={min} onChange={onMin} />
        </Stack>
        <Stack>
          <Typography>To:</Typography>
          <Input value={max} onChange={onMax} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default InventoryInput;
