import React from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
  Link,
} from "@mui/material";
import MapTwoToneIcon from "@mui/icons-material/MapTwoTone";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import p1 from "../assets/p1.jpeg";
import p2 from "../assets/p2.jpeg";
import p3 from "../assets/p3.jpeg";
import p4 from "../assets/p4.jpeg";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";


const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container maxWidth="xl">
          <Stack>
            <Header />
            <Stack
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={3}
            >
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
                <Grid item xs={4}>
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Welcome
                    </Typography>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            fontStyle: "italic",
                          }}
                        >
                          “We believe in a zero defect service”
                        </Typography>
                        <Typography
                          sx={{ ml: 5 }}
                          style={{ color: "#565656", fontSize: 13 }}
                        >
                          {"\t"}- Luc Vets, Founder and CEO
                        </Typography>
                      </Stack>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Our mission is to become an integral part of your
                        business. Our front office is your back office!
                      </Typography>

                      <Typography
                        style={{ color: "#971A3B", fontWeight: "bold" }}
                        onClick={() => navigate("/company#key-people")}
                      >
                        Meet the team »
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                    xs={{ width: "100%" }}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      About
                    </Typography>
                    <Stack
                      style={{ padding: "2px", margin: "5px" }}
                      spacing={2}
                    >
                      <Typography
                        style={{ fontWeight: "bold", textAlign: "left" }}
                      >
                        Luc Vets is a second generation diamantaire who founded
                        his company in the early seventies.
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        Back then the production was situated in the Kempen
                        area, a rural area just outside Antwerp.
                      </Typography>
                      <Typography
                        onClick={() => navigate("/company")}
                        style={{ color: "#971A3B", fontWeight: "bold" }}
                      >
                        Get to know Luc Vets Diamonds »
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Trade Fairs
                    </Typography>
                    <Stack
                      style={{ padding: "2px", margin: "5px" }}
                      spacing={2}
                    >
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        If Antwerp is too far away, then take the opportunity of
                        meeting us face to face on the following trade fairs:
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Grid  direction="row" spacing={1} sx={{ mb: 2 }} align= {isMobile ? 'flex-start' : 'center'} >
                <Grid item xs={8}>
                  <Stack  spacing={2}>
                    <Typography
                      
                      style={{
                        fontSize: 30,
                        fontWeight: "bold",
                      }}
                    >
                      Our Products
                    </Typography>
                    <Stack spacing={3}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Our polished goods are rounds only, brilliants and
                        single cuts, covering the full range of colors and
                        qualities in all sizes 1ct and down.
                      </Typography>
                      <Typography
                        
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        We firmly belief that Cut is the only "C" of the 4C's
                        that an untrained professional can appreciate. It is
                        true that the average consumer will not be able to make
                        the distinction between a VS or a SI grade. The same
                        consumer however will clearly see the difference in
                        "life" or "fire" of a well cut stone when it is mounted.
                      </Typography>
                      <Grid container direction = {isMobile ? 'column' : 'row'} spacing = {2}>
                        <Grid item xs={3}>
                        
                          <Box
                            sx={{
                              borderRadius: "16px",
                              width: 100,
                              height: 100,
                            }}
                            component="img"
                            alt="Your logo."
                            src={p1}
                          ></Box>

                          <Typography
                            align={isMobile ? 'flex-start' : 'center'}
                            onClick={() => navigate("/products#sam")}
                            style={{ color: "#971A3B", fontWeight: "bold" }}
                          >
                            Calibrated stars and melees »
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Box
                            sx={{
                              borderRadius: "16px",
                              width: 100,
                              height: 100,
                            }}
                            component="img"
                            alt="Your logo."
                            src={p2}
                          ></Box>

                          <Typography
                            align={isMobile ? 'flex-start' : 'center'}
                            onClick={() => navigate("/products#med")}
                            style={{ color: "#971A3B", fontWeight: "bold" }}
                          >
                            Medium sizes (pointers) »
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Box
                            sx={{ borderRadius: "16px" }}
                            component="img"
                            alt="Your logo."
                            src={p3}
                          ></Box>

                          <Typography
                            align={isMobile ? 'flex-start' : 'center'}
                            onClick={() => navigate("/products#90p")}
                            style={{ color: "#971A3B", fontWeight: "bold" }}
                          >
                            90 points and 1 cts »
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Box
                            sx={{ borderRadius: "16px" }}
                            component="img"
                            alt="Your logo."
                            src={p4}
                          ></Box>
                          <Typography
                            align={isMobile ? 'flex-start' : 'center'}
                            onClick={() => navigate("/products#cust")}
                            style={{ color: "#971A3B", fontWeight: "bold" }}
                          >
                            Customised goods »
                          </Typography>
                        </Grid>
                      </Grid>
                      
                    </Stack>
                  </Stack>
                </Grid>
                
              </Grid>
              <Grid container direction={isMobile ? 'column' : 'row'} justifyContent= "center">
              <Grid item xs={3}>
                  <Stack>
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: "bold",
                      }}
                    >
                      Contact Us
                    </Typography>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <MapTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Address
                          </Typography>
                          <Typography
                            style={{ fontWeight: "bold", color: "#971A3B" }}
                          >
                            Luc Vets BV
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Diamantclub Office 460
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Pelikaanstraat 62
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            2018 Antwerp
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Belgium
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <PhoneIphoneTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Direct Contact
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#971A3B",
                              fontSize: 12,
                            }}
                          >
                            Tel: +32-3-233.8887
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#971A3B",
                              fontSize: 12,
                            }}
                          >
                            Fax: +32-3-233.8504
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <EmailTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Email
                          </Typography>
                          <Link style={{ fontWeight: "bold", fontSize: 12 }}>
                            info@lvdiamonds.net
                          </Link>
                          <Link style={{ fontWeight: "bold", fontSize: 12 }}>
                            wimruyt@lvdiamonds.net
                          </Link>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6} >
                  <Map/>
                </Grid>
              </Grid>
              
              <Footer />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
