import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { useNavigate} from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import InventoryContext from "../contexts/InventoryContext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";


function TablePaginationActions(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { count, page, rowsPerPage, onPageChange } = props;
  const { pageState, setPageState } = useContext(InventoryContext);
  

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    setPageState(0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
    setPageState(pageState - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
    setPageState(pageState + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    setPageState(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function InventoryTable({ diamonds }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const { pageState } = useContext(InventoryContext);
  const [page, setPage] = useState(pageState);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
          {isMobile ? <TableRow>
             <TableCell style = {{fontSize: 12}} align="right">Price/Ct.</TableCell>
              <TableCell style = {{fontSize: 12}} align="right">Rap Discount %</TableCell>  
              <TableCell style = {{fontSize: 12}} align="right">Shape</TableCell>
              <TableCell style = {{fontSize: 12}} align="right">Carat</TableCell>
              <TableCell style = {{fontSize: 12}} align="right">Color</TableCell>
              <TableCell style = {{fontSize: 12}} align="right">Clarity</TableCell>
              <TableCell style = {{fontSize: 12}} align="right">Cut</TableCell>
            </TableRow> : <TableRow>
              <TableCell align="right">Shape</TableCell>
              <TableCell align="right">Carat</TableCell>
              <TableCell align="right">Color</TableCell>
              <TableCell align="right">Clarity</TableCell>
              <TableCell align="right">Cut</TableCell>
              <TableCell align="right">Polish</TableCell>
              <TableCell align="right">Symmetry</TableCell>
              <TableCell align="right">Lab</TableCell>
              <TableCell align="right">Fluoresence Int.</TableCell>
              <TableCell align="right">Table %</TableCell>
              <TableCell align="right">Total Depth %</TableCell>
              <TableCell align="right">Measurements</TableCell>
              <TableCell align="right">Price/Ct.</TableCell>
              <TableCell align="right">Rap Discount %</TableCell>  
            </TableRow>}
            
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? diamonds.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : diamonds
            ).map((row) => (
              isMobile ? 
              
              <TableRow
                key={row.diamond_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => navigate(`/Inventory/${row.cert_num}`)}
              >
                <TableCell style = {{fontSize: 12}} align="right" width="10%">
                  $ {Math.round(row.total_sales_price_in_currency / row.size)}
                </TableCell>
                <TableCell style = {{fontSize: 12}} align="right">
                  {Math.round(row.rap_discount * 100)}%
                </TableCell>
                <TableCell style = {{fontSize: 12}} align="right">{row.shape}</TableCell>
                <TableCell style = {{fontSize: 12}} align="right">{row.size}</TableCell>
                <TableCell style = {{fontSize: 12}} align="right">{row.color}</TableCell>
                <TableCell style = {{fontSize: 12}} align="right">{row.clarity}</TableCell>
                <TableCell style = {{fontSize: 12}} align="right" width="10%">
                  {row.cut}
                </TableCell>
              </TableRow>
              :
              <TableRow
                key={row.diamond_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => navigate(`/Inventory/${row.cert_num}`)}
              >
                <TableCell align="right">{row.shape}</TableCell>
                <TableCell align="right">{row.size}</TableCell>
                <TableCell align="right">{row.color}</TableCell>
                <TableCell align="right">{row.clarity}</TableCell>
                <TableCell align="right" width="10%">
                  {row.cut}
                </TableCell>
                <TableCell align="right" width="10%">
                  {row.polish}
                </TableCell>
                <TableCell align="right" width="10%">
                  {row.symmetry}
                </TableCell>
                <TableCell align="right">{row.lab}</TableCell>
                <TableCell align="right">{row.fluor_intensity}</TableCell>
                <TableCell align="right">{row.table_percent}%</TableCell>
                <TableCell align="right">{row.depth_percent}%</TableCell>
                <TableCell align="right">
                  {row.meas_length}x{row.meas_width}-{row.meas_depth}
                </TableCell>
                <TableCell align="right" width="10%">
                  $ {Math.round(row.total_sales_price_in_currency / row.size)}
                </TableCell>
                <TableCell align="right">
                  {Math.round(row.rap_discount * 100)}%
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ width: "500px" }}>
              <TablePagination
                rowsPerPageOptions={[15, 20, 25, { label: "All", value: -1 }]}
                colSpan={14}
                count={diamonds.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default InventoryTable;
