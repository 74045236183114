import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  Stack,
  Button,
  createTheme,
  ThemeProvider,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";

import InventoryContext from "../contexts/InventoryContext";
import InventoryTable from "./InventoryTable";
import InventoryForm from "./InventoryForm";
import InventoryInput from "./InventoryInput";
import CircularProgress from "@mui/material/CircularProgress";
import Script from '@gumgum/react-script-tag';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const CustomInventory = () => {
  const { isLoading, getDiamonds, diamonds, meta, setDefault, sortRap, filterDiamonds, diamondsFilter} =
    useContext(InventoryContext);

  const [filter, setFilter] = useState(false);
  const [sort, setSort] = useState(false);
  const [choice, setChoice] = React.useState(10)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onFilter = () => {
    setFilter(!filter);
  };

  const onSort = () => {
    sortRap(choice);
    setSort(!sort);
  };

  const onSearch = () => {
    filterDiamonds()
    setFilter(false);
    setDefault();
  };

  useEffect(() => {
    if (diamonds === undefined) {
      getDiamonds();
    }
  }, []);

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  


  if (isLoading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }} margin={20}>
        <CircularProgress />
      </Box>
    );
  }



  if (diamondsFilter !== undefined) {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
            >
              <Button
                onClick={onFilter}
                style={{ backgroundColor: "#971A3B", color: "white" }}
              >
                Filter
              </Button>

              <Typography>
                {diamondsFilter.length} Diamonds Found
              </Typography>
            </Stack>
            {/* <Stack direction = 'row' alignItems="flex-start" padding={2} sx = {{gap : 2}}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choice}
                label="Sort"
                onChange={handleChange}
              >
                <MenuItem value={10}>Price</MenuItem>
                <MenuItem value={20}>Carat</MenuItem>
                <MenuItem value={30}>Discount</MenuItem>
              </Select>
              <Button
                onClick={onSort}
                style={{ backgroundColor: "#971A3B", color: "white" }}
              >
                Sort
              </Button>
            </Stack>
            {sort ? <Container></Container> : null} */}

            {filter ? (
              <Container>
                  
                <Stack
                  alignItems="space-between"
                  justifyContent="center"
                  padding={2}
                  sx={{ gap: 2 }}
                >
                  <Grid
                    container
                    direction= {!isMobile ? "row" : "column"}
                    spacing={3}
                    alignItems="space-between"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} style={{ padding: 2 }}>
                      <Stack alignItems="flex-start" sx={{ gap: 2 }}>
                        <InventoryForm type="Clarity" />
                        <InventoryForm type="Fluorescence" />
                        <InventoryForm type="Shape" />
                        <InventoryForm type="Lab" />
                        <InventoryInput type="Price" />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} style={{ padding: isMobile ? 2 : 2 }}>
                      <Stack alignItems="flex-start" sx={{ gap: 2 }}>
                        <InventoryForm type="Color" />
                        <InventoryForm type="Cut" />
                        <InventoryForm type="Polish" />
                        <InventoryForm type="Symmetry" />
                        <InventoryInput type="Size" />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Button style={{ color: "#971A3B" }} onClick={onSearch}>
                    Search
                  </Button>
                </Stack>
              </Container>
            ) : null}
            <InventoryTable diamonds={diamondsFilter} />
          </Stack>
        </Box>
      </ThemeProvider>
    );
  } else {
      return(<Box>
          
        <Container maxWidth="xl">
            
          <Stack>
         <div id='diamondinstantinventory' data-apikey='283c0505-e6ba-4e7e-bbf3-267e90bd8c11' style={{ height: '1000px', width: '100%' }}></div> 
          <Script
                        src="https://instantinventory-widgets-cl59s.s3.amazonaws.com/diamonds/1.0.0/widget.js"
                        type="text/javascript"
                        
                    />
          </Stack>
        </Container>
      </Box>)
      
  }
};

export default CustomInventory;
