import React, {useState} from 'react'
import { send } from 'emailjs-com';
import {
  Box,
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
  TextField, 
  Button
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MapTwoToneIcon from "@mui/icons-material/MapTwoTone";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";



import Header from "../components/Header";
import Footer from "../components/Footer";

const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const changeName = (e) => {
    setName(e.target.value)
    
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
    
  }

  const changeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_3ja6ry5',
      'template_eri7d58',
      {from_name: name, 
      from_email: email, 
      message: message},
      'ZjBRGcXGkea-kOspq'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container maxWidth="xl">
          <Stack>
            <Header />
            <Stack
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={2}
            >
              
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={8}>
                  <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                    <Typography
                      style={{
                        fontSize: 30,

                        fontWeight: "bold",
                      }}
                    >
                      Contact Us
                    </Typography>
                    <Stack spacing={3}>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                      Please fill out the following form to contact Luc Vets BV:
                      </Typography>
                      <Grid container>
                      <Grid item>
                      <Stack spacing={3}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                          <TextField value={name} onChange={changeName} placeholder='Name'></TextField>
                          <TextField value={email} onChange={changeEmail} placeholder='Email Address'></TextField>
                        </Stack>
                        <TextField value={message} onChange={changeMessage} id="outlined-multiline-flexible" placeholder='Message' rows={4} cols={2} multiline/>
                        <Button onClick={onSubmit} style={{color:"#971A3B"}} size='medium'>Send</Button>
                      </Stack>
                      </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack>
                    
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <MapTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Address
                          </Typography>
                          <Typography
                            style={{ fontWeight: "bold", color: "#971A3B" }}
                          >
                            Luc Vets BV
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Diamantclub Office 460
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Pelikaanstraat 62
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            2018 Antwerp
                          </Typography>
                          <Typography
                            style={{ color: "#565656", fontSize: 12 }}
                          >
                            Belgium
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <PhoneIphoneTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Direct Contact
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#971A3B",
                              fontSize: 12,
                            }}
                          >
                            Tel: +32-3-233.8887
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#971A3B",
                              fontSize: 12,
                            }}
                          >
                            Fax: +32-3-233.8504
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Stack alignItems="center" spacing={2} direction="row">
                        <EmailTwoToneIcon />
                        <Stack>
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 20 }}
                          >
                            Email
                          </Typography>
                          <Link style={{ fontWeight: "bold", fontSize: 12 }}>
                            info@lvdiamonds.net
                          </Link>
                          <Link style={{ fontWeight: "bold", fontSize: 12 }}>
                            wimruyt@lvdiamonds.net
                          </Link>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Footer />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Contact;

