import React, { useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  FormControl,
  Stack,
  Select,
  Typography,
  MenuItem,
  Chip,
  OutlinedInput,
  Box,
} from "@mui/material";

import InventoryContext from "../contexts/InventoryContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(shape, diamondShape, theme) {
  return {
    fontWeight:
      diamondShape.indexOf(shape) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const shapes = ["Round", "Pear", "Oval"];
const colors = [
  { index: 10, value: "D" },
  { index: 20, value: "E" },
  { index: 30, value: "F" },
  { index: 40, value: "G" },
  { index: 50, value: "H" },
  { index: 60, value: "I" },
  { index: 70, value: "J" },
  { index: 80, value: "K" },
  { index: 90, value: "L" },
  { index: 100, value: "M" },
];

const clarity = [
  { index: 10, value: "FL" },
  { index: 20, value: "IF" },
  { index: 30, value: "VVS1" },
  { index: 40, value: "VVS2" },
  { index: 50, value: "VS1" },
  { index: 60, value: "VS2" },
  { index: 70, value: "SI1" },
  { index: 80, value: "SI2" },
  { index: 90, value: "SI3" },
  { index: 100, value: "I1" },
  { index: 110, value: "I2" },
];
const cut = [
  { index: 10, value: "Fair" },
  { index: 20, value: "Good" },
  { index: 30, value: "Very Good" },
  { index: 40, value: "Excellent" },
  { index: 50, value: "Ideal" },
];
const polish = [
  { index: 10, value: "Fair" },
  { index: 20, value: "Good" },
  { index: 30, value: "Very Good" },
  { index: 40, value: "Excellent" },
];
const symmetry = [
  { index: 10, value: "Fair" },
  { index: 20, value: "Good" },
  { index: 30, value: "Very Good" },
  { index: 40, value: "Excellent" },
];
const fluorescence = [
  "Very Strong",
  "Strong",
  "Medium",
  "Faint",
  "Very Slight",
  "None",
];
const lab = ["GIA", "HRD"];
const map = {
  Shape: shapes,
  Color: colors,
  Clarity: clarity,
  Cut: cut,
  Polish: polish,
  Symmetry: symmetry,
  Fluorescence: fluorescence,
  Lab: lab,
};

function InventoryForm({ type }) {
  const theme = useTheme();
  const {
    setShape,
    setColor,
    setLab,
    setPolish,
    setClarity,
    setSymmetry,
    setFluorescence,
    setCut,
  } = useContext(InventoryContext);
  const [value, setValue] = useState(map[type]);
  const [from, setFrom] = useState("10");
  const [to, setTo] = useState(
    map[type][map[type].length - 1].index?.toString()
  );

  const setMap = {
    Shape: setShape,
    Lab: setLab,
    Fluorescence: setFluorescence,
    Color: setColor,
    Polish: setPolish,
    Clarity: setClarity,
    Symmetry: setSymmetry,
    Cut: setCut,
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setMap[type](e.target.value);
  };

  const onDelete = (value) => {
    setValue(val => val.filter(val => val != value));
    setMap[type](val => val.filter(val => val != value));
  }

  const handleChangeTo = (e) => {
    if (parseInt(e.target.value) < from) {
      setFrom(parseInt(e.target.value).toString());
      setMap[type]((state) => [
        state[0],
        map[type].filter((row) => row.index === parseInt(e.target.value))[0]
          .value,
      ]);
    }
    setTo(e.target.value);
    setMap[type]((state) => [
      map[type].filter((row) => row.index === parseInt(e.target.value))[0]
        .value,
      state[1],
    ]);
  };

  const handleChangeFrom = (e) => {
    if (parseInt(e.target.value) > to) {
      setTo(parseInt(e.target.value).toString());

      setMap[type]((state) => [
        map[type].filter((row) => row.index === parseInt(e.target.value))[0]
          .value,
        state[1],
      ]);
    }
    setFrom(e.target.value);
    setMap[type]((state) => [
      state[0],
      map[type].filter((row) => row.index === parseInt(e.target.value))[0]
        .value,
    ]);
  };

  return (
    <Stack alignItems="flex-start">
      <Typography sx={{ color: "gray" }}>{type}</Typography>

      {type === "Shape" || type === "Lab" || type === "Fluorescence" ? (
        <FormControl>
          <Stack>
            <Typography>Select:</Typography>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={value}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={() => onDelete(value)}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {map[type].map((val) => (
                <MenuItem
                  key={val}
                  value={val}
                  style={getStyles(val, value, theme)}
                >
                  {val}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </FormControl>
      ) : (
        <Stack direction="row" sx={{ gap: 5 }}>
          <FormControl>
            <Stack>
              <Typography>From:</Typography>
              <Select
                labelId="label"
                id="select"
                value={from}
                onChange={handleChangeFrom}
              >
                {map[type].slice(0, map[type].length).map((item) => (
                  <MenuItem key={item.index} value={item.index}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </FormControl>
          <FormControl>
            <Stack>
              <Typography>To:</Typography>

              <Select
                labelId="label"
                id="select"
                value={to}
                onChange={handleChangeTo}
              >
                {map[type].slice(0, map[type].length).map((item) => (
                  <MenuItem key={item.index} value={item.index}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );
}

export default InventoryForm;
