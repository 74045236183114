import React, {useState, useContext} from 'react'
import {
  Box,
  Button, 
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
  Link,
  TextField, 
} from "@mui/material";

import HeaderInventory from "../components/HeaderInventory";
import Header from "../components/Header";
import Footer from "../components/Footer";
import InventoryContext from "../contexts/InventoryContext"
import CustomInventory from "../components/CustomInventory"

const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const Inventory = () => {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn'))
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const changeUsername = (e) => {
    setUsername(e.target.value)
    
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    
  }

  const onSubmit = (e) => {
    e.preventDefault()
    
    if (username == 'LucVets' && password == 'LucVets') {
      localStorage.setItem('loggedIn', true)
      setLoggedIn(true)
    }
    }

  if (loggedIn) {
    return (
      <Box>
        <Stack>
          <HeaderInventory/>
          <CustomInventory/>
          <Footer/>
        </Stack>
      </Box>
      
    )
  } else {
    return (
      <Box>
        <Container maxWidth="xl">
          <Stack>
            <Header/>
            <Stack alignItems = 'center' justifyContent = 'center' mt = {5} spacing = {2}>
                <TextField value={username} onChange={changeUsername} placeholder='username'/>
                <TextField value={password} onChange={changePassword} type="password" placeholder='password'/>
                <Button onClick={onSubmit} style={{backgroundColor:"#971A3B"}} variant="contained">Login</Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
    )
  }
  
    
}

export default Inventory
