import React from 'react'
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Company from './pages/Company';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Inventory from './pages/Inventory';
import Diamond from './pages/Diamond';
import Sustainability from './pages/Sustainability';


const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/company" element={<Company />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/sustainability" element={<Sustainability />} />
      <Route path="/inventory/:id" element={<Diamond />} />
    </Routes>
  )
}

export default Router
