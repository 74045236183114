import React, {useEffect, useRef} from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  ThemeProvider,
  Divider,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import responsible_council from "../assets/Responsible Jewellery Council Policy.pdf";
import social_policy from "../assets/HR and Social Policy RJC.pdf";
import supply_chain_policy from "../assets/Supply Chain Policy LUC VETS BV.pdf";
import supply_chain_report from "../assets/Supply Chain Reporting.pdf";
import annual_report from "../assets/Annual Sustainability Report.pdf";

import Header from "../components/Header";
import Footer from "../components/Footer";


const Sustainability = () => {
  const key = useRef(null)
  const location = useLocation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const executeScrollKey = () => key.current.scrollIntoView() 

  useEffect(() => {
    if (location.hash === '#key-people') {
      executeScrollKey()
    }
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <Box >
        <Container maxWidth="xl">
            <Header />
            <Stack
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={2}
              alignItems= 'center'
            >
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={3} alignItems="flex-start" >
                <Grid item xs={8}>
                  <Stack
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                      Our Commitment
                    </Typography>
                    <Stack
                      spacing={2}
                      style={{ padding: "2px", margin: "5px" }}
                    >
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        LUC VETS BV has taken the principles of modern sustainability at heart for many years. Social responsibility, environmental protection and good governance have always been important vectors to steer our business course. 
                      </Typography>
                      <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        We are a Member of the Responsible Jewellery Council since 2023. This standard measures our achievements in the fields of human rights, labour rights, environmental impact, product integrity, health and safety and many more. 
                       </Typography>

                       <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        In line with RJC Standards on reporting and disclosure, the following documents are made publicly available via this website:
                        </Typography>
                        <a href = {responsible_council} target = "_blank" rel="noopener noreferrer">Our RJC Policy and Commitment</a>
                        <a href = {social_policy} target = "_blank" rel="noopener noreferrer">Our Human Rights Policy</a>
                        <a href = {supply_chain_policy} target = "_blank" rel="noopener noreferrer">Our Supply Chain Policy</a>
                        <a href = {supply_chain_report} target = "_blank" rel="noopener noreferrer">Our annual Supply Chain Due Diligence Report</a>
                        <a href = {annual_report} target = "_blank" rel="noopener noreferrer"> Our annual Sustainability Report</a>
                        <Typography
                        style={{
                          color: "#565656",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        If you would like to know more about our sustainability efforts and performance, the various policies against which our company has been independently audited or if you have any grievances to address us, please send us an email at info@lvdiamonds.net.
                        
                      </Typography>
                      
                    </Stack>
                  </Stack>
                </Grid>
                
              </Grid>
              <Footer />
            </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Sustainability;
