import React, {useRef, useEffect} from "react";
import {
  Box,
  Container,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import dpl1 from "../assets/dpl1.jpeg";
import logos from "../assets/logos.jpeg";
import hccl35 from "../assets/hccl35.jpeg";
import HApointer from "../assets/H&A-pointer.jpeg";
import homeabout from "../assets/homeabout.jpeg";
import p1 from "../assets/p1.jpeg";
import p2 from "../assets/p2.jpeg";
import p3 from "../assets/p3.jpeg";
import p4 from "../assets/p4.jpeg";

import Header from "../components/Header";
import Footer from "../components/Footer";

const theme = createTheme({
  typography: {
    fontSize: 15,
    fontFamily: "Helvetica neue",
  },
});

const Products = () => {
  const location = useLocation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cust = useRef(null)
  const executeScrollCust = () => cust.current.scrollIntoView() 
  const sam = useRef(null)
  const executeScrollSam = () => sam.current.scrollIntoView() 
  const p = useRef(null)
  const executeScrollP = () => p.current.scrollIntoView()
  const med = useRef(null)
  const executeScrollMed = () => med.current.scrollIntoView()

  useEffect(() => {
    if (location.hash === "#cust") {
      executeScrollCust()
    } else if (location.hash === "#sam") {
      executeScrollSam()
    } else if (location.hash === "#90p") {
      executeScrollP()
    } else if (location.hash === "med") {
      executeScrollMed()
    }
  }, [location])

  

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container maxWidth="xl">
          <Header />
          <Stack
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={2}
          >
            <Grid container direction={isMobile ? 'column' : 'row'} spacing={3} alignItems={isMobile ? 'flex-start' : 'center'}>
              <Grid item xs={6}>
                <Stack
                  divider={<Divider orientation="horizontal" flexItem />}
                  spacing={2}
                >
                  <Typography style={{ fontSize: 30, fontWeight: "bold" }}>
                    Our Products
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2}>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        Our polished goods are rounds only, brilliants and
                        single cuts, covering the full range of colors and
                        qualities in all sizes 1ct and down.
                      </Typography>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        We firmly belief that Cut is the only "C" of the 4C's
                        that an untrained professional can appreciate. It is
                        true that the average consumer will not be able to make
                        the distinction between a VS or a SI grade. The same
                        consumer however will clearly see the difference in
                        "life" or "fire" of a well cut stone when it is mounted.
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  spacing={4}
                  direction={isMobile ? 'column' : 'row'}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Stack alignItems={isMobile ? 'flex-start' : 'center'} spacing={2}>
                    <Box
                      sx={{ borderRadius: "16px", width: 100, height: 100 }}
                      component="img"
                      alt="Your logo."
                      src={p1}
                    ></Box>
                    <Typography
                      align="center"
                      onClick={() => executeScrollSam()}
                      style={{ color: "#971A3B", fontWeight: "bold" }}
                    >
                      Calibrated stars and melees »
                    </Typography>
                  </Stack>
                  <Stack alignItems={isMobile ? 'flex-start' : 'center'} spacing={2}>
                    <Box
                      sx={{ borderRadius: "16px", width: 100, height: 100 }}
                      component="img"
                      alt="Your logo."
                      src={p2}
                    ></Box>
                    <Typography
                      align="center"
                      onClick={() => executeScrollMed()}
                      style={{ color: "#971A3B", fontWeight: "bold" }}
                    >
                      Medium sizes (pointers) »
                    </Typography>
                  </Stack>
                  <Stack alignItems={isMobile ? 'flex-start' : 'center'} spacing={2}>
                    <Box
                      sx={{ borderRadius: "16px" }}
                      component="img"
                      alt="Your logo."
                      src={p3}
                    ></Box>
                    <Typography
                      align="center"
                      onClick={() => executeScrollP()}
                      style={{ color: "#971A3B", fontWeight: "bold" }}
                    >
                      90 points and 1 cts »
                    </Typography>
                  </Stack>
                  <Stack alignItems={isMobile ? 'flex-start' : 'center'} spacing={2}>
                    <Box
                      sx={{ borderRadius: "16px" }}
                      component="img"
                      alt="Your logo."
                      src={p4}
                    ></Box>
                    <Typography
                      align="center"
                      onClick={() => executeScrollCust()}
                      style={{ color: "#971A3B", fontWeight: "bold" }}
                    >
                      Customised goods »
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Stack
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={2}
            >
              <Grid container direction={isMobile ? 'column' : 'row'} spacing={3} sx={{ mb: 2 }} alignItems={isMobile ? 'flex-start' : 'center'}>
                <Grid item xs={12}>
                  <Stack 
                    direction={isMobile ? 'column' : 'row-reverse'}
                    spacing={2}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    sx={{ mt: 3 }}>
                    
                    <Stack ref={sam} spacing={2} ml={isMobile ? 0 : 20}>
                      <Typography style={{ fontWeight: "bold", fontSize: 30 }}>
                        Calibrated Stars and Melees
                      </Typography>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        Stars and melees are available in either parcels or in
                        mm calibrated goods.
                      </Typography>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        Even the smallest sizes are polished within very
                        specific parameters in order to maximise the fire or
                        life of the stone. Straight MM goods are available
                        between 0.80mm up to 2.75mm, all bigger sizes are sold
                        in parcels.
                      </Typography>
                    </Stack>
                      <Box
                        sx={{ borderRadius: "16px", mt: 3, mr: 15 }}
                        component="img"
                        alt="Your logo."
                        src={homeabout}
                      ></Box>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    sx={{ mt: 3 }}
                  >
                    <Stack spacing={2} mr={isMobile ? 0 :20} >
                      <Stack ref={med} spacing={2}>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: 30 }}
                          
                        >
                          Medium Sizes (Pointers)
                        </Typography>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: 15 }}
                        >
                          Whether you need a DE IF or LM SI2 hearts and arrows
                          or ideal cut, we have it.
                        </Typography>
                        <Typography style={{ color: "#565656", fontSize: 15 }}>
                          Our production of pointers covers the whole range of
                          qualities and colors. Goods are broken down in very
                          tight grades to ensure a consistent and repeatable
                          quality in our parcels. Proof of our accuracy in
                          grading is the fact that 95% of our business is being
                          handled over the phone.
                        </Typography>
                        <Typography style={{ color: "#565656", fontSize: 15 }}>
                          All our goods are cut towards very strict parameters:
                        </Typography>
                      </Stack>
                      <Stack>
                        <Stack direction= 'row'>
                          <Typography
                            sx={{ ml: 5 }}
                            style={{ fontSize: 15, fontWeight: "bold" }}
                            alignItems='flex-start'
                          >
                            Tot Depth:
                          </Typography>
                          <Typography
                            sx={{ ml: 2 }}
                            style={{ color: "#565656", fontSize: 15 }}
                            align='flex-start'
                          >
                            59-62.9%
                          </Typography>
                        </Stack>
                        <Stack direction='row'>
                          <Typography
                            sx={{ ml: 5 }}
                            style={{ fontSize: 15, fontWeight: "bold" }}
                          >
                            Crown angle:
                          </Typography>
                          <Typography
                            sx={{ ml: 2 }}
                            style={{ color: "#565656", fontSize: 15 }}
                          >
                            33.7 – 35.5°
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            sx={{ ml: 5 }}
                            style={{ fontSize: 15, fontWeight: "bold" }}
                          >
                            Table:
                          </Typography>
                          <Typography
                            sx={{ ml: 2 }}
                            style={{ color: "#565656", fontSize: 15 }}
                          >
                            55- 60%
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            sx={{ ml: 5 }}
                            style={{ fontSize: 15, fontWeight: "bold" }}
                          >
                            Pavillion:
                          </Typography>
                          <Typography
                            sx={{ ml: 2 }}
                            style={{ color: "#565656", fontSize: 15 }}
                          >
                            40.7 – 41.3°
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        Although our standard ideal cut is sold in parcels we
                        can always provide you with straight H&A goods. Just
                        contact us for further information.
                      </Typography>
                    </Stack>
                    <Box
                      sx={{ borderRadius: "16px" }}
                      component="img"
                      alt="Your logo."
                      src={HApointer}
                    ></Box>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={isMobile ? 'column' : "row-reverse"}
                    spacing={2}
                    alignItems={isMobile ? 'flex-start' : "flex-start"}
                    sx={{ mt: 3 }}
                  >
                    
                    <Stack ref={p} spacing={2} ml={isMobile ? 0 : 20}>
                      <Typography style={{ fontWeight: "bold", fontSize: 30 }}>
                        90 Points and 1Cts
                      </Typography>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        These are the largest sizes that we cut and we sell them on
                        a stone by stone basis.
                      </Typography>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        All stones are certified with HRD or GIA and we always
                        have a good quantity of GIA Triple Excellent or HRD H&A
                        stones available.
                      </Typography>
                      <Stack direction={isMobile ? 'column' : "row"}>
                        <Box
                          sx={{ borderRadius: "16px" }}
                          component="img"
                          alt="Your logo."
                          src={logos}
                        ></Box>
                      </Stack>
                      
                    </Stack>
                    <Box
                        sx={{ borderRadius: "16px", mt: 3, mr: 15 }}
                        component="img"
                        alt="Your logo."
                        src={hccl35}
                      ></Box>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={isMobile ? 'column' : "row"}
                    spacing={2}
                    alignItems={isMobile ? 'center' : "center"}
                    sx={{ mt: 3 }}
                  >
                    <Stack  ref={cust} spacing={2}>
                      <Typography style={{ fontWeight: "bold", fontSize: 30 }}>
                        Customised Goods
                      </Typography>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        Very small full and single cut
                      </Typography>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        We have the capability of polishing extremely small
                        sizes down to 1000 pcs per carat or 0.60mm diameter.
                        These extreme small sizes are not part of the regular
                        production but can be manufactured should you have a
                        specific demand for this.
                      </Typography>
                      <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
                        Large size Single Cut
                      </Typography>
                      <Typography style={{ color: "#565656", fontSize: 15 }}>
                        If you need to restore or build a unique piece of
                        jewellery that requires larger size ideal single cut let
                        us know. We can manufacture excellent single cuts up to
                        70 points.
                      </Typography>
                    </Stack>
                    <Box
                      sx={{ borderRadius: "16px", mt: 3 }}
                      component="img"
                      alt="Your logo."
                      src={dpl1}
                    ></Box>
                  </Stack>
                </Grid>
              </Grid>
              <Footer />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Products;
